import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./styles/App.css";
import store from "./redux/store";
import NetworkStatus from "./commons/NetworkStatus";
// import Home from "./Home";

// Lazy load pages
const Home = lazy(() => import("./Home"));
const Details = lazy(() => import("./Details"));
const Success = lazy(() => import("./Success"));
const DBS = lazy(() => import("./bankForms/DBS"));
const CITI = lazy(() => import("./bankForms/CITI"));
const SCB = lazy(() => import("./bankForms/SCB"));
const HSBC = lazy(() => import("./bankForms/HSBC"));
const BOC = lazy(() => import("./bankForms/BOC"));
const CIMB = lazy(() => import("./bankForms/CIMB"));
const MAYBANK = lazy(() => import("./bankForms/MAYBANK"));
const OCBC = lazy(() => import("./bankForms/OCBC"));
const RHB = lazy(() => import("./bankForms/RHB"));
const SBI = lazy(() => import("./bankForms/SBI"));
const UOB = lazy(() => import("./bankForms/UOB"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/upload-success"
            element={
              <div
                style={{
                  backgroundImage: `url('/MyInfoMockUp.png')`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "100vh",
                  width: "100vw",
                }}
              ></div>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/apply" element={<Details />} />
          <Route path="/success" element={<Success />} />
          <Route path="/bankForms/DBS/:leadId" element={<DBS />} />
          <Route path="/bankForms/CITI/:leadId" element={<CITI />} />
          <Route path="/bankForms/SCB/:leadId" element={<SCB />} />
          <Route path="/bankForms/HSBC/:leadId" element={<HSBC />} />
          <Route path="/bankForms/BOC/:leadId" element={<BOC />} />
          <Route path="/bankForms/CIMB/:leadId" element={<CIMB />} />
          <Route path="/bankForms/MAYBANK/:leadId" element={<MAYBANK />} />
          <Route path="/bankForms/UOB/:leadId" element={<UOB />} />
          <Route path="/bankForms/OCBC/:leadId" element={<OCBC />} />
          <Route path="/bankForms/RHB/:leadId" element={<RHB />} />
          <Route path="/bankForms/SBI/:leadId" element={<SBI />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <NetworkStatus />
      <App />
    </Provider>
  );
}

export default AppWrapper;
